<template>
    <div class="task-page-documents">
        <section v-if="isContract">
            <div v-if="isFullyExecuted" class="mb-3">
                <div class="task-page-documents__title">
                    <GreenCheckIcon/>
                    Signed Contract
                </div>
                <div class="task-page-documents__area task-page-documents__area_green">
                    <span :title="documentFullName" class="task-page-documents__link"
                          @click="toggleDocumentPreview('tasks', task.id, 'preview-uploaded-file')">
                        Preview Signed Contract
                    </span>
                </div>
                <div class="task-page-documents__buttons" v-if="isGeneralContractor">
                    <CButton :disabled="loading" color="danger" variant="outline" @click="confirmDeleteSignedContract">
                        <CSpinner v-if="loading" size="sm" color="danger"/>
                        <span>Delete Signed Contract</span>
                    </CButton>
                </div>
            </div>
            <div>
                <div class="task-page-documents__title">
                    <CIcon name="cilCrown"/>
                    Sent Contract
                </div>
                <div class="task-page-documents__area task-page-documents__area_blue">
                    <span class="task-page-documents__link" @click="toggleDocumentPreview('tasks', task.id)">
                        Preview Contract Sent
                    </span>
                </div>
                <div class="task-page-documents__buttons">
                    <CButton v-if="isSubContractor && !isFullyExecuted" :disabled="loading" color="primary"
                             variant="outline" @click="downloadTaskFile">
                        <CSpinner v-if="loading" size="sm" color="primary"/>
                        <span>Download</span>
                    </CButton>
                    <CButton v-if="isGeneralContractor && canUpload" class="d-block ml-auto mt-3" color="primary"
                             variant="outline" @click="uploadSignedContract">
                        Upload signed contract
                    </CButton>
                </div>
            </div>
        </section>
        <section v-if="isAcknowledge">
            <div>
                <div class="task-page-documents__area task-page-documents__area_green">
                    <span title="Document" class="task-page-documents__link"
                          @click="toggleDocumentPreview('tasks', task.id)">
                        Preview Acknowledgement Document
                    </span>
                </div>

                <!--                <div class="task-page-documents__buttons">-->
                <!--                    <CButton :disabled="loading" color="primary" variant="outline" @click="download('origin')">-->
                <!--                        <CSpinner v-if="loading" size="sm" color="primary"/>-->
                <!--                        <span v-else>Download</span>-->
                <!--                    </CButton>-->
                <!--                    <CButton v-if="isAcknowledgeButton" :disabled="loading" color="primary" @click="handleAcknowledge">-->
                <!--                        Acknowledge-->
                <!--                    </CButton>-->
                <!--                </div>-->
            </div>
        </section>
        <section v-if="isRequest">
            <!--            <TaskPageDocumentFileArea-->
            <!--                v-if="isSubContractor && canEdit && !getRequestDocumentsByStatus('In review').length"-->
            <!--                title="Document" color="blue" :upload="true" :loading="loading" @submit="submitForReviewDocument"/>-->

            <!--            <TaskPageDocumentFileArea v-for="doc in getRequestDocumentsByStatus('Approved')"-->
            <!--                                      :key="doc.id" title="Document" color="green" icon="check-green"-->
            <!--                                      :document-name="doc.name"-->
            <!--                                      @linkClicked="toggleDocumentPreview('content', doc.id)"/>-->

            <!--            <div class="task-page-documents__buttons" v-if="isRequest && isCompleted && isGeneralContractor">-->
            <!--                <CButton :disabled="loading" color="danger" variant="outline"-->
            <!--                         @click="confirmDeleteUploadedRequestDocument">-->
            <!--                    <CSpinner v-if="loading" size="sm" color="danger"/>-->
            <!--                    <span>Delete Uploaded Document</span>-->
            <!--                </CButton>-->
            <!--            </div>-->

            <!--            <TaskPageDocumentFileArea v-if="isNoFilesForInternal" title="Document" color="blue" icon="check-green">-->
            <!--                <CButton v-if="isGeneralContractor && canUpload" :disabled="true" class="d-block" color="primary">-->
            <!--                    Reject & Send Message-->
            <!--                </CButton>-->
            <!--            </TaskPageDocumentFileArea>-->

            <!--            <TaskPageDocumentFileArea v-for="doc in getRequestDocumentsByStatus('In review')"-->
            <!--                                      :key="doc.id" title="Document" color="green" :document-name="doc.name"-->
            <!--                                      :can-delete="isSubContractor && getRequestDocumentsByStatus('In review').length"-->
            <!--                                      @linkClicked="toggleDocumentPreview('origin', doc.id)"-->
            <!--                                      icon="check-green" @delete="deleteFile(doc.id)">-->
            <!--                <CButton :disabled="loading" color="primary" variant="outline" @click="download('origin')">-->
            <!--                    <CSpinner v-if="loading" size="sm" color="primary"/>-->
            <!--                    <span>Download</span>-->
            <!--                </CButton>-->
            <!--                <CButton v-if="isGeneralContractor && canUpload" class="d-block" color="primary"-->
            <!--                         @click="$emit('reject', doc)">-->
            <!--                    Reject & Send Message-->
            <!--                </CButton>-->
            <!--            </TaskPageDocumentFileArea>-->

            <!--            <div v-if="sampleDocuments.length > 0">-->
            <!--                <TaskPageDocumentFileArea v-for="doc in sampleDocuments" :key="doc.id" title="Sample" color="blue"-->
            <!--                                          icon="sample" :document-name="doc.name"-->
            <!--                                          @linkClicked="toggleDocumentPreview('origin', doc.id)"/>-->
            <!--            </div>-->
            <!--            <div v-else>-->
            <TaskPageDocumentFileArea title="Sample" color="blue" icon="sample"
                                      document-name="Preview Document Request Sample"
                                      @linkClicked="toggleDocumentPreview('tasks', task.id)"/>
            <!--            </div>-->
        </section>
    </div>
    <ConfirmDialog ref="confirmDialog"/>
    <DocumentPreviewer :visible="documentPreviewer.visible" :type="documentPreviewer.type"
                       :route="documentPreviewer.route" :type-id="documentPreviewer.typeId"
                       @closed="toggleDocumentPreview(null, null)"/>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import {permissionError} from '@/services/Notify/Toasts';
import {parseError} from '@/utils/api';
import TaskPageDocumentFileArea from '@/components/TaskPages/TaskPageDocumentFileArea.vue';
import DocumentPreviewer from '@/components/Documents/DocumentPreviewer.vue';
import GreenCheckIcon from '@/components/Icons/GreenCheckIcon.vue';
import Tasks from "@/api/v2/endpoints/Tasks";
import documentDownload from "@/mixin/documentDownload";

export default {
    name: 'TaskPageDocument',
    components: {TaskPageDocumentFileArea, ConfirmDialog, DocumentPreviewer, GreenCheckIcon},
    inject: ['toast'],
    mixins: [documentDownload],
    props: ['task', 'canUpload', 'canEdit', 'mode', 'taskType', 'userType'],
    data() {
        return {
            extension: 'pdf',
            loading: false,
            sampleDocuments: [],
            documentPreviewer: {
                type: null,
                typeId: null,
                route: null,
                visible: false,
            }
        };
    },
    computed: {
        isNoFilesForInternal() {
            return (
                !this.getRequestDocumentsByStatus('In review').length
                && this.task.status !== 'Completed'
                && this.task.status !== 'Revoked'
                && this.isGeneralContractor
            );
        },
        requestedDocuments() {
            if (!this.isRequest) return [];

            return this.task.requested_document.length ? this.task.requested_document : [];
        },
        isFullyExecuted() {
            if (this.taskType !== 'Contract') return false;

            return this.task.status === 'Fully Executed';
        },
        isCompleted() {
            return this.task.status === 'Completed';
        },
        isAcknowledgeButton() {
            return this.isSubContractor && this.task.status === 'Pending Acknowledgement';
        },
        isContract() {
            return this.taskType === 'Contract';
        },
        isAcknowledge() {
            return this.taskType === 'Acknowledge';
        },
        isRequest() {
            return this.taskType === 'Request';
        },
        isSubContractor() {
            return this.userType === 'external';
        },
        isGeneralContractor() {
            return this.userType === 'internal';
        },
        documentFullName() {
            if (this.isContract) return this.task.contract.name;

            if (this.isAcknowledge) return this.task.document.name;

            return null;
        },
    },
    created() {
        this.sampleDocuments = this.getRequestDocumentsByStatus('Sample');
    },
    methods: {
        async deleteFile(id) {
            if (!this.canEdit) {
                this.$notify(permissionError);
                return;
            }
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to delete uploaded document?',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then((res) => {
                    if (res) {
                        this.$http.tasks
                            .deleteDocument(id)
                            .then((response) => {
                                this.toast('success', response.data.message);
                                this.$emit('update', response.data.task);
                            })
                            .catch(({response}) => {
                                this.toast('error', response.data.message);
                            });
                    }
                });
        },
        confirmDeleteSignedContract() {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to delete the signed contract?',
                    info: 'This action can\'t be undone.',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then(confirmed => !confirmed || this.deleteSignedContract());
        },
        deleteSignedContract() {
            this.toggleLoading();

            Tasks
                .deleteUploadedContract(this.task.id)
                .then(response => {
                    this.toast('success', 'Signed contract successfully deleted!');
                    this.$emit('update', response.data);
                })
                .finally(() => this.toggleLoading());
        },
        confirmDeleteUploadedRequestDocument() {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to delete the uploaded document?',
                    info: 'This action can\'t be undone.',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then(confirmed => !confirmed || this.deleteUploadedRequestDocument());
        },
        deleteUploadedRequestDocument() {
            this.toggleLoading();

            Tasks
                .deleteUploadedRequestDocument(this.task.id)
                .then(response => {
                    this.toast('success', 'Uploaded document successfully deleted!');
                    this.$emit('update', response.data);
                })
                .finally(() => this.toggleLoading());
        },
        submitForReviewDocument(file) {
            if (!this.canEdit) {
                this.$notify(permissionError);
                return;
            }
            this.loading = true;

            const formData = new FormData();

            formData.append('task_id', this.task.id);
            formData.append('file', file);

            this.$http.tasks
                .submitForReviewDocument(formData)
                .then((res) => {
                    this.toast('success', res.data.message);
                    this.$emit('update', res.data.task);
                })
                .catch((err) => {
                    err = parseError(err);
                    this.toast('error', err.message);
                    if (err.status === 422) {
                        this.errors = err.validationMessages;
                    }
                })
                .finally(() => (this.loading = false));
        },
        getRequestDocumentsByStatus(status) {
            if (!this.isRequest) return [];

            return this.requestedDocuments.filter((doc) => doc.status === status);
        },
        uploadSignedContract() {
            this.$router.push({name: 'ProjectScopeUpload', params: {scopeId: this.task.scope_id}});
        },
        async handleAcknowledge() {
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to acknowledge the task? This action can not be undone',
                    onlyConfirmButton: true,
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.acknowledged();
                });
        },
        acknowledged() {
            this.loading = true;
            this.$http.tasks
                .confirmAcknowledge({task_id: this.task.id})
                .then((res) => {
                    this.toast('success', res.data.message);
                    this.$emit('update', res.data.data);
                })
                .catch((e) => this.toast('error', e.response.data.message))
                .finally(() => (this.loading = false));
        },
        downloadTaskFile() {
            this.handleDownloadPromise(
                Tasks.downloadFile(this.task.id),
                `task-${this.task.id}-file`,
                this.task.name
            );
        },
        toggleDocumentPreview(type, typeId, route = 'preview-file') {
            this.documentPreviewer.type = type;
            this.documentPreviewer.typeId = typeId;
            this.documentPreviewer.route = route;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
};
</script>

<style lang="scss">
.task-page-documents {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__modal {
        width: 688px;
    }

    &__buttons {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        & button {
            min-width: 110px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #1c262f;
        margin-bottom: 12px;

        & svg {
            color: #0068ad;
            min-width: 20px;
        }
    }

    &__area {
        display: flex;
        align-items: center;
        padding: 0 16px;
        min-height: 56px;
        border-radius: 8px;

        &_green {
            border: 1px dashed #008a6e;
            background: rgba(159, 233, 218, 0.24);
        }

        &_blue {
            border: 1px dashed #00437a;
            background: rgba(171, 231, 250, 0.25);
        }
    }

    &__link {
        color: #0068ad;
        cursor: pointer;
        font-size: 16px;
        text-decoration: underline;
    }
}
</style>
