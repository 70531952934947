import router from '@/router'

export class TaskService {
    static async checkTypeAndRedirect(expectedType, taskType, params) {
        if (expectedType !== taskType) {
            let name = expectedType
            switch (taskType) {
                case 'Request documents':
                    name = 'TaskRequest'
                    break
                case 'Contracts':
                    name = 'TaskContracts'
                    break
                case 'Acknowledge':
                    name = 'TaskAcknowledge'
                    break
            }
            return await router.push({name: name, params: params})
        }

        return true
    }
}
