<template>
    <CModal scrollable size="xl" class="py-4" :visible="visible" @close="$emit('closed')">
        <CModalHeader>
            <CModalTitle>Document Preview</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body">
            <iframe :src="source"></iframe>
        </CModalBody>
    </CModal>
</template>

<script>
import {baseURL, getVerUri} from '@/services/ApiService';

const DOCUMENT_TYPES = ['tasks', 'templates', 'standard-templates'];

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
            validator: (value) => DOCUMENT_TYPES.includes(value),
        },
        typeId: {
            type: Number,
            required: true,
        },
        route: {
            type: String,
            default: 'preview',
        },
    },
    data() {
        return {
            validated: false,
        };
    },
    computed: {
        source() {
            return baseURL + getVerUri(`${this.type}/${this.typeId}/${this.route}`, 2);
        },
    }
};
</script>

<style scoped lang="scss">
.content-body {
    height: 100vh;

    iframe {
        height: 95%;
        width: 100%;
    }
}
</style>
