<template>
    <div class="app-page task-page">
        <div class="task-page__content">
            <div class="task-page__body">
                <div class="task-page__section">
                    <span :title="task.project.name" class="task-page__project-name text-overflow-ellipsis">
                        Project: <strong class="">{{ task.project.name }}</strong>
                    </span>
                    <div class="task-page__task-name text-overflow-ellipsis">
                        <img v-if="task.is_main" src="@/assets/images/crown-emblem.svg" alt="crown"/>
                        <div :title="task.name" class="text-overflow-ellipsis">
                            <span>{{ task.name }}</span>
                        </div>
                    </div>
                    <div class="task-page__task-action">
                        <CIcon name="cilCheckCircle"/>
                        Action: {{ task.required_action }}
                    </div>
                    <p v-for="(inst, i) in instructions" :key="'inst' + i" class="task-page__task-instruction">
                        {{ instructions.length > 1 ? ++i + '.' : '' }} {{ inst }}
                    </p>
                </div>

                <div class="task-page__section">
                    <TaskPageDocument :task="task" :can-upload="canEdit" :can-edit="canEdit" :task-type="taskType"
                                      :user-type="userType" @update="$emit('update', $event)"
                                      @reject="fileToReject = $event"/>
                </div>

                <!--                <div v-if="isRejectedDocuments" class="task-page__section">-->
                <!--                    <TaskPageRejectedDocuments :task="task" @showReason="modals.reason = $event"/>-->
                <!--                </div>-->

                <!--                <div class="task-page__section">-->
                <!--                    <span class="task-page__task-id">-->
                <!--                        Task ID: <strong>{{ task.taskID }}</strong>-->
                <!--                    </span>-->
                <!--                    <div class="task-page__form">-->
                <!--                        <div class="task-page__form-item">-->
                <!--                            <CNSelect v-model="formData.internal_assignee_id" :searchable="true" :caret="true"-->
                <!--                                      label="Internal Assignee" :disabled="isSub || !canEdit"-->
                <!--                                      :options="normalizeUsersList(usersList.internal_assigners)"-->
                <!--                                      :class="{ 'task-page__readonly-field': isSub || !canEdit }"-->
                <!--                                      @change="formDirty = true"/>-->
                <!--                        </div>-->
                <!--                        <div class="task-page__form-item">-->
                <!--                            <CNSelect v-model="formData.external_assignee_id" :searchable="true" :caret="true"-->
                <!--                                      label="External Assignee*" :disabled="!canEdit"-->
                <!--                                      :class="{ 'task-page__readonly-field': !canEdit }"-->
                <!--                                      :options="normalizeUsersList(usersList.external_assigners)"-->
                <!--                                      @change="formDirty = true"/>-->
                <!--                        </div>-->
                <!--                        <div v-if="isSignerFields" class="task-page__form-item">-->
                <!--                            <CNSelect v-model="formData.internal_signer_id" :searchable="true" :caret="true"-->
                <!--                                      label="Internal Signer*" :options="normalizeUsersList(usersList.internal_signers)"-->
                <!--                                      :disabled="isSub || !canEdit"-->
                <!--                                      :class="{ 'task-page__readonly-field': isSub || !canEdit }"-->
                <!--                                      @change="formDirty = true"/>-->
                <!--                        </div>-->
                <!--                        <div v-if="isSignerFields" class="task-page__form-item">-->
                <!--                            <CNSelect v-model="formData.external_signer_id" :searchable="true" :caret="true"-->
                <!--                                      label="External Signer*" :disabled="!canEdit"-->
                <!--                                      :class="{ 'task-page__readonly-field': !canEdit }"-->
                <!--                                      :options="normalizeUsersList(usersList.external_signers)"-->
                <!--                                      @change="formDirty = true"/>-->
                <!--                        </div>-->
                <!--                        <div class="task-page__form-item">-->
                <!--                            <CNSelect v-model="task.assigned_company.name" disabled-->
                <!--                                      :class="{ 'task-page__readonly-field': true }" label="Assigned Company"-->
                <!--                                      :options="[task.assigned_company.name]"/>-->
                <!--                        </div>-->
                <!--                        <div class="w-100">-->
                <!--                            <CNTextarea id="task-note" v-model="formData.note" :disabled="isSub || !canEdit"-->
                <!--                                        :class="{ 'task-page__readonly-field': isSub || !canEdit }"-->
                <!--                                        label="Description/Note:" style="height: 150px" :max-length="1000"-->
                <!--                                        @update:model-value="formDirty = true"/>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
            <!--            <div class="task-page__panel">-->
            <!--                <div class="task-page__panel-category">Status</div>-->
            <!--                <div class="task-page__panel-statuses">-->
            <!--                    <div :class="['task-page__panel-status', `task-page__panel-status_${getStatusColor(task.status)}`]">-->
            <!--                        {{ task.status }}-->
            <!--                    </div>-->
            <!--                    <transition name="fade">-->
            <!--                        <div v-if="task.is_overdue" class="task-page__panel-status task-page__panel-status_red">-->
            <!--                            Overdue-->
            <!--                        </div>-->
            <!--                    </transition>-->
            <!--                </div>-->

            <!--                <div v-if="isApproveAvailable" class="task-page__panel-approve">-->
            <!--                    Action:-->
            <!--                    <button @click="onApprove">-->
            <!--                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
            <!--                            <path-->
            <!--                                fill-rule="evenodd"-->
            <!--                                clip-rule="evenodd"-->
            <!--                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 14.9997L3 9.99969L4.41 8.58969L8 12.1697L15.59 4.57969L17 5.99969L8 14.9997Z"-->
            <!--                                fill="#0BBD99"/>-->
            <!--                        </svg>-->
            <!--                        Approve-->
            <!--                    </button>-->
            <!--                    <button @click="onReject">-->
            <!--                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
            <!--                            <path-->
            <!--                                fill-rule="evenodd"-->
            <!--                                clip-rule="evenodd"-->
            <!--                                d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15.0004 13.5904L13.5904 15.0004L10.0004 11.4104L6.41039 15.0004L5.00039 13.5904L8.59039 10.0004L5.00039 6.41039L6.41039 5.00039L10.0004 8.59039L13.5904 5.00039L15.0004 6.41039L11.4104 10.0004L15.0004 13.5904Z"-->
            <!--                                fill="#FF5257"/>-->
            <!--                        </svg>-->
            <!--                        Reject-->
            <!--                    </button>-->
            <!--                </div>-->

            <!--                <div class="task-page__panel-category">Timeline</div>-->
            <!--                <FLCalendar v-model="formData.assigned_date" label="Assign Date"-->
            <!--                            :class="{ 'task-page__readonly-field': isSub || !canEdit }"-->
            <!--                            :disabled="isSub || !canEdit" @update:model-value="formDirty = true"/>-->
            <!--                <DueDateSelector class="task-page__form-field"-->
            <!--                                 :class="{ 'task-page__disabled-field': isSub || !canEdit }"-->
            <!--                                 :assign_date="formData.assigned_date" :recurrence-disabled="task.priority_type === 1"-->
            <!--                                 :due_date="formData.due_date" :is_indefinite="task.is_indefinite" :settings="formData"-->
            <!--                                 :permissions="['recurrence', 'assign_date', 'due_date']"-->
            <!--                                 @modalDisplayChange="isRecurrenceModal = $event" @saved="onRecurrenceSave">-->
            <!--                    <template #title> Due date : {{ formatDate(formData.due_date) }}</template>-->
            <!--                </DueDateSelector>-->
            <!--                <CNInput :model-value="formatDate(formData.complete_date)" label="Completed Date"-->
            <!--                         :class="{ 'task-page__readonly-field': true }" :disabled="true"/>-->
            <!--                <CNSelect v-model="formData.priority" :class="{ 'task-page__readonly-field': isSub || !canEdit }"-->
            <!--                          label="Priority" :options="priorityOptions" :disabled="isSub || !canEdit"/>-->
            <!--            </div>-->
        </div>

        <!--        <StickyFooter v-if="isFooter">-->
        <!--            <CButton style="min-width: 100px" color="primary" variant="outline"-->
        <!--                     :disabled="loading || !canEdit || !formDirty || isRecurrenceModal" @click="onCancel">-->
        <!--                Cancel-->
        <!--            </CButton>-->
        <!--            <CButton style="min-width: 100px" color="primary" :disabled="loading || !canEdit || isRecurrenceModal"-->
        <!--                     @click="save">-->
        <!--                <CSpinner v-if="loading" size="sm"/>-->
        <!--                <span v-else>Save</span>-->
        <!--            </CButton>-->
        <!--            <CButton v-if="isRevokeButton" class="ml-auto d-flex align-items-center" variant="ghost"-->
        <!--                     :disabled="loading || isRecurrenceModal" @click="onRevoke">-->
        <!--                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">-->
        <!--                    <path-->
        <!--                        fill-rule="evenodd"-->
        <!--                        clip-rule="evenodd"-->
        <!--                        d="M8.99935 0.167969C4.39935 0.167969 0.666016 3.9013 0.666016 8.5013C0.666016 13.1013 4.39935 16.8346 8.99935 16.8346C13.5993 16.8346 17.3327 13.1013 17.3327 8.5013C17.3327 3.9013 13.5993 0.167969 8.99935 0.167969ZM13.166 9.33463H4.83268V7.66797H13.166V9.33463Z"-->
        <!--                        fill="#FF1F26"/>-->
        <!--                </svg>-->

        <!--                <span class="text-error text-decoration-underline d-block ml-2">Revoke Task</span>-->
        <!--            </CButton>-->
        <!--        </StickyFooter>-->
    </div>

    <!--    <AppModal content-class="reject-modal" :visible="!!fileToReject" title="Rejecting Document"-->
    <!--              text="Explaining why you reject this file will help your partner with providing the proper document"-->
    <!--              @close="onRejectModalClose">-->
    <!--        <p class="reject-modal__tip">Why Do I reject this file?</p>-->
    <!--        <CNTextarea v-model="rejectMessage" :required="true" :invalid="rejectMessageError && !rejectMessage"-->
    <!--                    :error="rejectMessageError && !rejectMessage ? 'Field is required' : ''" class="form-control"-->
    <!--                    label="Leave a comment here" :max-length="1000"></CNTextarea>-->
    <!--        <template #footer>-->
    <!--            <CButton color="primary" variant="outline" @click="onRejectModalClose"> Cancel</CButton>-->
    <!--            <CButton color="danger" @click="rejectDocument(fileToReject)"> Reject File</CButton>-->
    <!--        </template>-->
    <!--    </AppModal>-->
    <!--    <AppModal content-class="reject-modal" :visible="modals.reason" title="Document Rejected"-->
    <!--              text="Your partner has rejected your previous document for the reasons listed below."-->
    <!--              @close="modals.reason = null">-->
    <!--        <p class="reject-modal__tip reject-modal__tip_red">Rejected: {{ formatDate(modals.reason.created_at) }}</p>-->
    <!--        <CNTextarea v-model="modals.reason.rejection_reason" :disabled="true" class="form-control"-->
    <!--                    label="Reason" :max-length="1000"></CNTextarea>-->
    <!--        <template #footer>-->
    <!--            <CButton color="primary" variant="outline" @click="modals.reason = null"> Cancel</CButton>-->
    <!--            <CButton color="primary" @click="modals.reason = null"> Got it!</CButton>-->
    <!--        </template>-->
    <!--    </AppModal>-->
    <!--    <AppModal content-class="reject-modal" :visible="modals.approve" title="Are you sure you want to approve the file?"-->
    <!--              @close="modals.approve = null">-->
    <!--        <template #footer>-->
    <!--            <CButton color="primary" variant="outline" @click="modals.approve = null"> Cancel</CButton>-->
    <!--            <CButton color="primary" @click="approveDocument"> Confirm</CButton>-->
    <!--        </template>-->
    <!--    </AppModal>-->
    <!--    <CModal class="overflow-modal" :visible="modals.cancel" backdrop="static">-->
    <!--        <div class="app-modal task-page__modal">-->
    <!--            <div class="app-modal__icon">-->
    <!--                <CIcon class="task-page__modal-icon" name="cilXCircle"/>-->
    <!--            </div>-->
    <!--            <h4 class="app-modal__title">Cancel changes?</h4>-->
    <!--            <p class="app-modal__text">Are you sure you want to cancel changes?</p>-->
    <!--            <div class="app-modal__buttons">-->
    <!--                <CButton variant="outline" color="primary" @click="modals.cancel = false">Cancel</CButton>-->
    <!--                <CButton color="primary" @click="cancel">Confirm</CButton>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </CModal>-->
    <!--    <CModal class="overflow-modal" :visible="modals.revoke" backdrop="static">-->
    <!--        <div class="app-modal task-page__modal">-->
    <!--            <div class="app-modal__icon">-->
    <!--                <CIcon class="task-page__modal-icon" name="cil-warning"/>-->
    <!--            </div>-->
    <!--            <h4 class="app-modal__title">Are you sure you want to revoke the task?</h4>-->
    <!--            <p class="app-modal__text">This action can not be undone.</p>-->
    <!--            <div class="app-modal__buttons">-->
    <!--                <CButton variant="outline" color="primary" @click="modals.revoke = false">Cancel</CButton>-->
    <!--                <CButton color="primary" @click="revoke">Confirm</CButton>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </CModal>-->
    <ConfirmDialog ref="confirmDialog"/>
</template>

<script>
import CNSelect from '@/components/ui/CNSelect/CNSelect.vue';
import CNTextarea from '@/components/ui/CNTextarea/CNTextarea.vue';
import StickyFooter from '@/components/Forms/Partials/StickyFooter.vue';
import FLCalendar from '@/components/Forms/Elements/FLCalendar.vue';
import CNInput from '@/components/ui/CNInput/CNInput.vue';
import {isEqual} from '@/utils/helper';
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import moment from 'moment/moment';
import useTaskSignersAndAssigners from '@/composables/useTaskSignersAndAssigners';
import TaskPageDocument from '@/components/TaskPages/TaskPageDocument.vue';
import {CLOSED_TASKS} from "@/domain/Entities/Task/taskStatuses";
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import TaskPageRejectedDocuments from '@/components/TaskPages/TaskPageRejectedDocuments.vue';
import AppModal from '@/components/Modals/AppModal.vue';
import {permissionError} from '@/services/Notify/Toasts';
import store from '@/store';

export default {
    name: 'TaskPage',
    components: {
        AppModal,
        TaskPageRejectedDocuments,
        DueDateSelector,
        TaskPageDocument,
        ConfirmDialog,
        CNInput,
        FLCalendar,
        StickyFooter,
        CNTextarea,
        CNSelect,
    },
    inject: ['toast'],
    props: ['task', 'instructions', 'taskType', 'userType', 'canUpload', 'users', 'form', 'tick'],
    setup() {
        const {usersList, getUsers, loadingUsers} = useTaskSignersAndAssigners();
        return {usersList, getUsers, loadingUsers};
    },
    data() {
        return {
            loading: false,

            formOrigin: null,
            formData: {
                internal_assignee_id: '',
                external_assignee_id: '',
                internal_signer_id: '',
                external_signer_id: '',
                assigned_company_id: '',
                note: '',

                assigned_date: '',
                due_date: '',
                complete_date: '',
                priority: '',
            },

            modals: {
                cancel: false,
                revoke: false,
                reason: false,
                approve: false,
            },

            isRecurrenceModal: false,
            fileToReject: null,
            rejectMessage: '',
            rejectMessageError: false,
        };
    },
    computed: {
        authUser() {
            return store.getters.user;
        },
        isRejectedDocuments() {
            return this.taskType === 'Request' && this.task.requested_document.find((d) => d.status === 'Rejected');
        },
        isApproveAvailable() {
            return (
                this.taskType === 'Request' &&
                this.isGeneral &&
                this.canEdit &&
                this.task.requested_document.find((d) => d.status === 'In review')
            );
        },
        isFooter() {
            return this.task.status !== 'Fully Executed' && this.canEdit;
        },
        isSignerFields() {
            return this.taskType !== 'Acknowledge';
        },
        isRevokeButton() {
            return this.isGeneral && this.task.status !== 'Revoked' && this.canEdit;
        },
        formDirty() {
            if (!this.formData || !this.formOrigin) return false;

            return !isEqual(this.formData, this.formOrigin);
        },
        isGeneral() {
            return this.userType === 'internal';
        },
        isSub() {
            return this.userType === 'external';
        },
        priorityOptions() {
            return [
                {value: 'Normal', name: 'Normal'},
                {value: 'High', name: 'High'},
            ];
        },
        canEdit() {
            return this.$store.state.tasks.permissions.edit && !CLOSED_TASKS.includes(this.task.status);
        },
    },
    watch: {
        formDirty(val) {
            this.$emit('formDirtyChange', val);
        },
        tick() {
            this.setFormFields();
        },
        fileToReject(val) {
            if (!val) this.rejectMessage = '';
        },
    },
    created() {
        this.setFormFields();
        this.getUsers(this.task.scope_id);
    },
    methods: {
        onRejectModalClose() {
            this.rejectMessageError = false;
            this.rejectMessage = '';
            this.fileToReject = null;
        },
        canApproveOrReject() {
            let check = this.task.internal_assignee_id === this.authUser.id;
            if (!check) {
                this.$notify({
                    type: 'info',
                    content: 'Only the internal assignee has permissions to approve or reject the document',
                });
            }

            return check;
        },
        onApprove() {
            if (!this.canEdit) {
                this.$notify(permissionError);
                return;
            }

            if (!this.canApproveOrReject()) return;

            this.modals.approve = true;
        },
        approveDocument() {
            const document = this.task.requested_document.find((d) => d.status === 'In review');

            if (document) {
                this.$http.tasks
                    .approveDocument({
                        task_id: this.task.id,
                        document_id: document.id,
                    })
                    .then((response) => {
                        this.$emit('update', response.data.task);
                        this.toast('success', response.data.message);
                        this.modals.approve = false;
                    })
                    .catch(({response}) => this.toast('error', response.data.message));
            }
        },
        onReject() {
            const document = this.task.requested_document.find((t) => t.status === 'In review');

            if (document) {
                this.fileToReject = {id: document.id};
            }
        },
        rejectDocument(doc) {
            if (!this.rejectMessage) {
                this.rejectMessageError = true;
            }

            if (!this.canEdit) {
                this.$notify(permissionError);
                this.fileToReject = null;
                return;
            }

            if (!this.canApproveOrReject()) {
                this.fileToReject = null;
                return;
            }

            const payload = {
                task_id: this.task.id,
                document_id: doc.id,
                message: this.rejectMessage,
            };

            this.$http.tasks.rejectDocument(payload).then((res) => {
                console.log(res.data.task);
                this.$emit('update', res.data.task);
                this.toast('success', res.data.message);
                this.fileToReject = null;
            });
        },
        onRecurrenceSave(recurrenceSettings) {
            const normalizeSettings = {
                ...recurrenceSettings,
                assigned_date: recurrenceSettings.assign_date,
            };

            this.formData = {...this.formData, ...normalizeSettings};
        },
        formatDate(date) {
            if (!date) return '';

            return moment.utc(date).local().format('MM/DD/YYYY');
        },
        revoke() {
            this.$http.tasks.revokeTask(this.task.id).then((res) => {
                this.toast('success', res.data.message);
                this.modals.revoke = false;
                this.$emit('update', res.data.data);
            });
        },
        onRevoke() {
            this.modals.revoke = true;
        },
        onCancel() {
            this.modals.cancel = true;
        },
        uploadDocument() {
            this.$emit('upload');
        },
        normalizeUsersList(list) {
            return list.map((user) => ({...user, name: user.label}));
        },
        setFormFields() {
            const formKeys = Object.keys(this.formData);

            formKeys.forEach((key) => {
                const valueFormTask = this.task[key];

                if (valueFormTask && typeof valueFormTask === 'object') return (this.formData[key] = this.task[key].id);
                if (valueFormTask) this.formData[key] = valueFormTask;
            });

            this.formData = {...this.formData, ...this.task.recurrence_component};
            this.formOrigin = {...this.formData};
        },
        uploadSignedContract() {
            this.$router.push({name: 'ProjectScopeUpload', params: {scopeId: this.task.scope_id}});
        },
        getStatusColor(status) {
            const data = {
                'Signature Required': 'violet',
                Overdue: 'red',
                Cancelled: 'red',
                'Fully Executed': 'green',
                Completed: 'green',
                'Pending Approval': 'green',
                'Pending Acknowledgement': 'light-blue',
                'Pending Submission': 'dark-blue',
                Revoked: 'red',
                'Scheduled to Send': 'orange',
            };

            return data[status];
        },

        save() {
            const data = {
                task_id: this.task.id,
                ...this.formData,
                assign_date: this.formatDate(this.formData.assigned_date),
            };

            this.loading = true;
            this.$http.tasks
                .editTasks(data)
                .then((res) => {
                    this.toast('success', res.data.message);
                    this.$emit('update', res.data.data);
                })
                .catch((e) => this.toast('success', e.response.data.message))
                .finally(() => (this.loading = false));
        },
        cancel() {
            this.formData = {...this.formOrigin};
            this.modals.cancel = false;
        },
    },
};
</script>

<style lang="scss">
.reject-modal {
    width: 688px;

    & .form-control {
        border: none;
        padding: 0;
    }

    &__tip {
        font-size: 12px;
        font-weight: 600;
        color: rgba(103, 122, 137, 1);

        &_red {
            color: red !important;
        }
    }

    & .common-modal__buttons {
        & .btn {
            min-width: 200px;
        }

        & .btn-danger {
            color: white;
        }
    }
}

.task-page {
    & .form-floating {
        & label {
            color: #677a89 !important;
            font-size: 15px !important;
        }
    }

    &__form-field {
        width: 100%;
        border: 1px solid #9fafbc;
        border-radius: 8px;
        font-size: 16px;

        & .due-date__selector {
            background: #fff;
        }
    }

    &__modal {
        width: 688px;

        &-icon {
            height: 70px !important;
            width: 70px !important;
            color: #677a89;
        }
    }

    &__readonly-field {
        opacity: 0.8;
    }

    &__disabled-field {
        opacity: 0.8;
        pointer-events: none;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        padding-bottom: 80px;
    }

    &__body {
        max-width: 850px;
    }

    &__panel {
        background: #f2f5f8;
        padding: 16px;
        width: 324px;
        min-height: 573px;
        flex-grow: 0;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-approve {
            display: flex;
            align-items: center;
            gap: 16px;

            & button {
                display: flex;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                gap: 8px;
                color: rgba(28, 38, 47, 1);
                font-weight: 600;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-statuses {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
        }

        &-status {
            height: 24px;
            padding: 0 12px;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: white;
            border-radius: 48px;

            &_green {
                background: rgba(11, 189, 153, 1);
            }

            &_blue {
                background: rgba(0, 86, 148, 1);
            }

            &_light-blue {
                background: rgba(0, 129, 194, 1);
            }

            &_dark-blue {
                background: rgba(4, 53, 102, 1);
            }

            &_orange {
                background: rgba(255, 145, 0, 1);
            }

            &_violet {
                background: #b12cf1;
            }

            &_red {
                background: #ff1f26;
            }
        }

        &-category {
            font-size: 18px;
            font-weight: 600;
            color: #1c262f;
            padding-bottom: 8px;
            border-bottom: 1px solid #9fafbc;
        }
    }

    &__section {
        margin-bottom: 32px;
    }

    &__project-name {
        font-size: 16px;
        color: #677a89;
        display: block;
        margin-bottom: 24px;

        & strong {
            font-weight: 600;
            font-size: 16px;
        }
    }

    &__task-name {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 24px;
        font-weight: 600;
        color: #1c262f;
        border-bottom: 1px solid #c3cdd5;
        margin-bottom: 24px;

        & img {
            height: 24px;
        }
    }

    &__task-action {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        font-size: 18px;
        color: #1c262f;
        margin-bottom: 12px;

        & svg {
            color: #677a89;
        }
    }

    &__task-instruction {
        font-size: 16px;
        color: #1c262f;
        margin-bottom: 0;
    }

    &__file-area {
        display: flex;
        align-items: center;
        padding: 0 16px;
        min-height: 56px;
        border-radius: 8px;
        margin-bottom: 12px;

        &_green {
            border: 1px dashed #008a6e;
            background: rgba(159, 233, 218, 0.24);
        }

        &_blue {
            border: 1px dashed #00437a;
            background: rgba(171, 231, 250, 0.25);
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #1c262f;
            margin-bottom: 12px;

            & svg {
                color: #0068ad;
            }
        }
    }

    &__file-link {
        color: #0068ad;
        font-size: 16px;
        text-decoration: underline;
    }

    &__task-id {
        display: block;
        margin-bottom: 24px;
        font-size: 16px;
        color: #1c262f;

        & strong {
            font-weight: 600;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        & .form-control {
            border: none;
            padding: 0;
        }

        &-item {
            width: 348px;
        }
    }
}
</style>
